































import { Component, PropSync, Vue } from "vue-property-decorator";

@Component
export default class CosmopolSubstitutes extends Vue {
  @PropSync("selected", {
    required: false,
    default: null,
    type: [String, Number],
  })
  private _selected: string | number;

  private saveSubstitute(): void {
    //console.log("saveSubstitute");
  }
  private cancelSubstitute(): void {
    //console.log("cancelSubstitute");
  }
}
